import React, { useEffect, useState } from "react";
import { Outlet, useNavigate, useSearchParams } from "react-router-dom";
import HeaderLogo from "../../components/HeaderLogo";
import { SmallButton } from "../../components/Buttons";
import { useDispatch, useSelector } from "react-redux";
import { logout, userLogin } from "../../redux/actions/auth";
import LoadingSpinner from "../../components/Common/LoadingSpinner";
import { CheckClientKYCExpiry } from "../../redux/actions/ClientKYCGuest";
import KYCError from "../../components/KYC/KYCError";
import Footer from "../../components/Footer";
import ErrorPage from "../../components/ErrorPage";

const KYCLayout = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const clientId = searchParams.get("client_id");
  const hash = searchParams.get("hash");

  useEffect(() => {
    const checkKYC = async () => {
      const data = {
        client_id: clientId,
        hash: hash,
      };
      setLoading(true);
      const res = await dispatch(CheckClientKYCExpiry(data));
      if (res.success === false) {
        setError(res.message);
        localStorage.removeItem("kyc_user");
      }
      // if ((!user || !user.token) && res.is_owner) {
      if ((!user || !user.token) && res.success) {
        const data = {
          email: process.env.REACT_APP_KYC_USER_EMAIL,
          password: process.env.REACT_APP_KYC_USER_PASSWORD,
        };
        await dispatch(userLogin(data));
      }

      setLoading(false);
    };

    if ((!user || !user.token) && hash && clientId) {
      checkKYC();
    } else if (user && user.token) {
      localStorage.removeItem("kyc_user");
    }
    // eslint-disable-next-line
  }, [user]);

  const handleLogout = async () => {
    setLoading(true);
    await dispatch(logout());
    navigate("/");
    setLoading(false);
  };

  const render = () => {
    if (error) {
      return (
        <div className="md:max-w-[992px] px-2 mx-auto flex flex-col items-center justify-center min-h-[80vh]">
          <KYCError error={error} login={true} />
        </div>
      );
    } else if (!hash || !clientId) {
      return (
        <ErrorPage
          errorCode="403"
          message="Forbidden"
          description="You don't have permission to access this resource"
        />
      );
    } else {
      return <Outlet />;
    }
  };

  return (
    <>
      <div className="md:max-w-[992px] mx-auto flex flex-col md:pt-6">
        <HeaderLogo />
        {user && user.token && (
          <div className="inline-flex space-x-2 px-2">
            <SmallButton onClick={handleLogout} text={"Logout"} cancel={true} />
          </div>
        )}
        {loading && <LoadingSpinner />}
      </div>
      {render()}
      <Footer />
    </>
  );
};

export default KYCLayout;
