import React, { useState } from "react";
import { useDispatch } from "react-redux";
import Modal from "../../../Common/Modal";
import FormInputField from "../../../FormInputField";
import CommonButton from "../../../Buttons/CommonButton";
import useForm from "../../../../hooks/useForm";
import HangTightLoading from "../../../Common/HangTightLoading";
import SuccessMessage from "../../../Common/SuccessMessage";
import { addBeneficiary } from "../../../../redux/actions/TRrequest/Beneficiary";
import ErrorMessage from "../../../Common/ErrorMessage";
import Label from "../../../Label";
import CountriesInput from "../../../Common/CountriesInput";
import useAllCountries from "../../../../hooks/useAllCountries";
import { useCurrencies, useModules } from "../../../../hooks";
import CurrenciesInput from "../../../Common/CurrenciesInput";
import { GetValidationErrors } from "../../../../helper";
import Dropdown from "../../../Dropdown";

const AddBeneficiaryModal = ({
  addBeneficiaryModalOpen,
  setaddBeneficiaryModalOpen,
  setRefreshBeneficiary,
  children,
}) => {
  const [isloading, setIsloading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);
  const account_number = useForm("");
  const iban = useForm("");
  const full_name = useForm("");
  const company_name = useForm("");
  const bank_name = useForm("");
  const swift_code = useForm("");
  const bank_address = useForm("");
  const beneficiary_address = useForm("");
  const selectedBeneficiaryCountry = useForm("");
  const selectedBankBeneficiaryCountry = useForm("");
  const selectedCurrency = useForm("");
  const allCountries = useAllCountries();
  const currencies = useCurrencies("fiat");
  const isOpenPaydEnabled = useModules("OpenPayd");
  const selectBeneficiaryType = useForm("");

  const dispatch = useDispatch();
  const handleOnCloseModal = () => {
    setaddBeneficiaryModalOpen(false);
    setTimeout(() => {
      setSuccess(false);
      resetAllFields();
      setError(null);
    }, 500);
  };

  const handleOnSubmit = async (e) => {
    e.preventDefault();
    setIsloading(true);
    setError(null);

    let hasError = false;

    [bank_name, bank_address, beneficiary_address].forEach(
      (field) => {
        if (!field.validate()) {
          hasError = true;
        }
      }
    );

    if (isOpenPaydEnabled) {
      [
        selectedBeneficiaryCountry,
        selectedBankBeneficiaryCountry,
        selectedCurrency,
      ].forEach((field) => {
        if (!field.value.id) {
          field.setError(true);
          hasError = true;
          setError("Please select the required fields");
        }
      });
    }

    
    let data = {
      account_number: account_number.value,
      iban: iban.value,
      // name: full_name.value,
      bank_name: bank_name.value,
      swift_code: swift_code.value,
      bank_address: bank_address.value,
      address: beneficiary_address.value,
      beneficiary_type: selectBeneficiaryType.value,
    };
    
    if (selectBeneficiaryType.value === 'company') {
      hasError = !company_name.validate();
      data['name'] = company_name.value;
    } else {
      hasError = !full_name.validate();
      data['name'] = full_name.value;
    }
    
    if (hasError) {
      setIsloading(false);
      return;
    }
    

    data["currency"] = selectedCurrency.value.id;
    data["beneficiary_country"] = selectedBeneficiaryCountry.value.id;
    data["bank_account_country"] = selectedBankBeneficiaryCountry.value.id;
    
    try {
      let res = await dispatch(addBeneficiary(data, isOpenPaydEnabled));
      if (res.success) {
        setSuccess(true);
        resetAllFields();
        setRefreshBeneficiary(true);
      } else {
        if (res.errors) {
          setError(GetValidationErrors(res.errors));
          const requiredFields = { account_number, iban };

          ["account_number", "iban"].forEach((field) => {
            if (res.errors[field]) {
              requiredFields[field].setError(true);
            }
          });
        } else {
          setError(res.error);
        }
      }
    } catch (error) {
      console.log("Add beneficiary error: ", error);
      if (!success) setError(error.message);
    }
    setIsloading(false);
  };

  const resetAllFields = () => {
    account_number.reset();
    iban.reset();
    full_name.reset();
    bank_name.reset();
    swift_code.reset();
    bank_address.reset();
    beneficiary_address.reset();
    selectedBeneficiaryCountry.reset();
    selectedBankBeneficiaryCountry.reset();
    selectedCurrency.reset();
    selectBeneficiaryType.reset();
    company_name.reset();
  };

  const onSelectOption = (option, setOption) => {
    setOption.setValue(option);
    setOption.setError("");
  };

  return (
    <>
      <div onClick={() => setaddBeneficiaryModalOpen(true)}>{children}</div>
      <Modal
        isOpen={addBeneficiaryModalOpen}
        handleOnCloseModal={handleOnCloseModal}
        header={isloading || success ? false : true}
        headerTitle={"Add new beneficiary"}
        className="bg-popup-bg w-[432px] overflow-scroll hide-scrollbar bg-PopupBG shadow-xl rounded-3xl"
      >
        {error && !isloading && (
          <div className="mx-6">
            <ErrorMessage message={error} onClose={() => setError(null)} />
          </div>
        )}
        {!isloading && !success && (
          <div className="px-6 pt-">
            <div className="text-body-text text-sm font-medium leading-tight tracking-tight">
              Beneficiary details
            </div>
            <div className="my-2 space-y-2">
              {/* {isOpenPaydEnabled && ( */}
              <>
                <Dropdown
                  id="select-beneficiary-dropdown"
                  name="select-beneficiary-dropdown"
                  placeholder="Select a beneficiary type"
                  label="Beneficiary type"
                  options={[
                    { label: "Company", value: "company" },
                    { label: "Individual", value: 'individual' },
                  ]}
                  onChange={selectBeneficiaryType.onChange}
                  value={selectBeneficiaryType.value}
                />
                <CurrenciesInput
                  name="currency"
                  label="Currency"
                  placeholder="Choose your Currency"
                  currencies={currencies}
                  SelectedCurrency={selectedCurrency.value}
                  onChange={(option) =>
                    onSelectOption(option, selectedCurrency)
                  }
                  error={selectedCurrency.error}
                >
                  <div>
                    <Label
                      htmlFor={"currency"}
                      className="block text-xs font-medium text-body-text pb-1"
                      text="Currency"
                    />
                    <div className="flex items-center justify-betwee space-x-3">
                      <img
                        className="rounded-full w-8 h-8"
                        src={selectedCurrency.value.logo}
                        alt="flag"
                      />
                      <Label
                        htmlFor={"currency"}
                        className="text-sm font-medium text-body-text"
                        text={`${selectedCurrency.value.name} (${selectedCurrency.value.code})`}
                      />
                    </div>
                  </div>
                </CurrenciesInput>

                <CountriesInput
                  name="beneficiary_country"
                  label="Beneficiary country"
                  placeholder="Choose your beneficiary country"
                  countries={allCountries}
                  SelectedCountry={selectedBeneficiaryCountry.value}
                  onChange={(option) =>
                    onSelectOption(option, selectedBeneficiaryCountry)
                  }
                  phonecode={false}
                  error={selectedBeneficiaryCountry.error}
                >
                  <div>
                    <Label
                      htmlFor={"beneficiary_country"}
                      className="block text-xs font-medium text-body-text pb-1"
                      text="Beneficiary country"
                    />
                    <div className="flex items-center justify-betwee space-x-3">
                      <img
                        className="rounded-full w-8 h-8"
                        src={selectedBeneficiaryCountry.value.flag}
                        alt="flag"
                      />
                      <Label
                        htmlFor={"beneficiary_country"}
                        className="text-sm font-medium text-body-text"
                        text={`${selectedBeneficiaryCountry.value.name}`}
                      />
                    </div>
                  </div>
                </CountriesInput>

                <CountriesInput
                  name="bank_account_country"
                  label="Bank account country"
                  placeholder="Choose your Bank account country"
                  countries={allCountries}
                  SelectedCountry={selectedBankBeneficiaryCountry.value}
                  onChange={(option) =>
                    onSelectOption(option, selectedBankBeneficiaryCountry)
                  }
                  phonecode={false}
                  error={selectedBankBeneficiaryCountry.error}
                >
                  <div>
                    <Label
                      htmlFor={"bank_account_country"}
                      className="block text-xs font-medium text-body-text pb-1"
                      text="Bank account country"
                    />
                    <div className="flex items-center justify-betwee space-x-3">
                      <img
                        className="rounded-full w-8 h-8"
                        src={selectedBankBeneficiaryCountry.value.flag}
                        alt="flag"
                      />
                      <Label
                        htmlFor={"bank_account_country"}
                        className="text-sm font-medium text-body-text"
                        text={`${selectedBankBeneficiaryCountry.value.name}`}
                      />
                    </div>
                  </div>
                </CountriesInput>
              </>
              {/* )} */}
            </div>

            <form onSubmit={handleOnSubmit}>
              <div className="my-2 space-y-2">
                <FormInputField
                  required={true}
                  label={"Account Number"}
                  name={"account_number"}
                  id={"account_number"}
                  type={"number"}
                  placeholder={"########"}
                  value={account_number.value}
                  onChange={account_number.onChange}
                  {...account_number}
                />
                <FormInputField
                  required={false}
                  label={"IBAN"}
                  name={"iban"}
                  id={"iban"}
                  type={"text"}
                  placeholder={"#### #### #### #### ##"}
                  value={iban.value}
                  onChange={iban.onChange}
                  {...iban}
                />
                
                {selectBeneficiaryType.value === 'company' || null ? (
                  <FormInputField
                    required={true}
                    label={"Company name"}
                    name={"company_name"}
                    id={"company_name"}
                    type={"text"}
                    placeholder={"Company name of the account holder"}
                    value={company_name.value}
                    onChange={company_name.onChange}
                  />
                ) : (
                  <FormInputField
                    required={true}
                    label={"Full Name"}
                    name={"full_name"}
                    id={"full_name"}
                    type={"text"}
                    placeholder={"Full name of the account holder"}
                    value={full_name.value}
                    onChange={full_name.onChange}
                  />
                )}

                <FormInputField
                  required={true}
                  label={"Beneficiary Address"}
                  name={"beneficiary_address"}
                  id={"beneficiary_address"}
                  type={"text"}
                  placeholder={"Address of the beneficiary"}
                  value={beneficiary_address.value}
                  onChange={beneficiary_address.onChange}
                />
                <FormInputField
                  required={true}
                  label={"Bank Name"}
                  name={"bank_name"}
                  id={"bank_name"}
                  type={"text"}
                  placeholder={"Bank name of the beneficiary account"}
                  value={bank_name.value}
                  onChange={bank_name.onChange}
                />
                <FormInputField
                  required={true}
                  label={"BIC/Swift Code"}
                  name={"swift_code"}
                  id={"swift_code"}
                  type={"text"}
                  placeholder={"Swift code of the beneficiary bank"}
                  value={swift_code.value}
                  onChange={swift_code.onChange}
                />
                <FormInputField
                  required={true}
                  label={"Beneficiary Bank Address"}
                  name={"bank_address"}
                  id={"bank_address"}
                  type={"text"}
                  placeholder={"Bank address of the beneficiary account"}
                  value={bank_address.value}
                  onChange={bank_address.onChange}
                />
              </div>
              <div className="flex justify-end py-6">
                <CommonButton
                  type="submit"
                  // onClick={handleOnSubmit}
                  // disabled={
                  //   !account_number.value ||
                  //   // !iban.value ||
                  //   !full_name.value ||
                  //   !bank_name.value ||
                  //   !swift_code.value ||
                  //   !bank_address.value
                  // }
                >
                  Confirm beneficiary
                </CommonButton>
              </div>
            </form>
          </div>
        )}

        {success && (
          <div className="p-6">
            <SuccessMessage
              title="Beneficiary added"
              description={`You've successfully added ${full_name.value} to your list of beneficiaries.`}
              handleCloseButton={handleOnCloseModal}
            />
          </div>
        )}

        {isloading && <HangTightLoading />}
      </Modal>
    </>
  );
};

export default AddBeneficiaryModal;
