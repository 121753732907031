/* Removing the OTP verification for now. Keeping the code commented out for future use. */
import React, { useState } from "react";
import Modal from "../../Common/Modal";
import SelectAccountList from "../../Common/SelectAccountList";
import { useDispatch, useSelector } from "react-redux";
import AccountDropdown from "../../Common/AccountDropdown";
import CommonButton from "../../Buttons/CommonButton";
import AccountPopUp from "../../Transactions/Transfer/AccountPopUp";
import SuccessMessage from "../../Common/SuccessMessage";
import { balanceCertificateRequest } from "../../../redux/actions/ServiceRequests/balanceCertificateRequest";
import HangTightLoading from "../../Common/HangTightLoading";
// import EnterOTP from "../../Common/EnterOTP";
// import { serviceRequestVerifyOTP } from "../../../redux/actions/ServiceRequests/serviceRequestVerifyOTP";
import useClientAccounts from "../../../hooks/useClientAccounts";
import useClientProfiles from "../../../hooks/useClientProfiles";

const BalanceCertificate = ({ balanceCertificate, setBalanceCertificate }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [page, setPage] = useState(1);
  const [clientid, setClientId] = useState("");
  const { clientProfiles, loadingProfiles } = useClientProfiles();
  const { activeAccount } = useSelector((state) => state.activeAccount);
  const dispatch = useDispatch();
  const [isAccountPop, setIsAccountPop] = useState(false);
  const [account, setAccount] = useState(activeAccount);
  // const [otp, setOtp] = useState(["", "", "", "", "", ""]);
  // const [requestId, setRequestId] = useState("");
  // const [resendDisabled, setResendDisabled] = useState(true);
  // const [counter, setCounter] = useState(60);
  const clientAllAccounts = useClientAccounts();
  const [filterAccounts, setFilterAccounts] = useState([]);

  const closeModal = () => {
    setBalanceCertificate(false);
    setTimeout(() => {
      setClientId("");
      setError("");
      setPage(1);
    }, 300);
  };

  // useEffect(() => {
  //   if (!isLoading && resendDisabled) {
  //     const timer = setInterval(() => {
  //       setCounter((prevCounter) => {
  //         if (prevCounter === 1) {
  //           setResendDisabled(false);
  //           clearInterval(timer);
  //           return 0;
  //         }
  //         return prevCounter - 1;
  //       });
  //     }, 1000);

  //     return () => clearInterval(timer);
  //   }
  // }, [isLoading, resendDisabled]);

  // const handleOTP = async () => {
  //   const data = {
  //     request_id: requestId,
  //     otp: otp.join(""),
  //   };
  //   setIsLoading(true);
  //   const res = await dispatch(serviceRequestVerifyOTP(data));
  //   if (res.success) {
  //     setRequestId("");
  //     setPage(4);
  //   } else {
  //     setError(res.error);
  //   }
  //   setIsLoading(false);
  //   setOtp(["", "", "", "", "", ""]);
  // };

  // const handleResendCode = () => {
  //   setCounter(60);
  //   setResendDisabled(true);
  //   handleBalanceCertificateRequest();
  // };

  const handleSelectAccount = () => {
    let accounts = clientAllAccounts.filter(
      (acc) => acc.title.split(" (")[0] === clientid.name
    );
    setAccount(accounts[0]);
    setFilterAccounts(accounts);
    setPage(2);
    return;
  };

  const handleBalanceCertificateRequest = async () => {
    if (account && clientid) {
      try {
        setIsLoading(true);
        const res = await dispatch(
          balanceCertificateRequest({
            client_id: clientid.id,
            account_id: account.id,
          })
        );
        if (res.success) {
          setPage(3);
          setIsLoading(false);
          // setRequestId(res.data.request_id);
          // setCounter(60);
        } else {
          setIsLoading(false);
          setError(res.message);
        }
      } catch (error) {
        setIsLoading(false);
        setError(error.message);
      }
    }
  };

  const handleChangeAccount = (account) => {
    setAccount(account);
    setIsAccountPop(false);
    setError("");
  };

  return (
    <>
      <Modal
        isOpen={balanceCertificate}
        handleOnCloseModal={closeModal}
        // page !== 4 in case of OTP feature being used
        header={isLoading || page === 3 ? false : true}
        // headerTitle={page === 3 ? "Enter OTP" : "Balance Certificate"}
        headerTitle={"Balance Certificate"}
        backButton={page === 2 ? true : false}
        backButtonAction={() => (page > 1 ? setPage(page - 1) : setPage(1))}
        className="bg-popup-bg w-[432px] max-h-[578px] overflow-scroll hide-scrollbar shadow-xl rounded-3xl"
      >
        <div className="px-6 max-[360px]:px-4 pb-4">
          {!isLoading && page === 1 && (
            <SelectAccountList
              clientsAccounts={clientProfiles}
              CloseAddAcccount={clientid}
              setCloseAddAccount={setClientId}
              handleSelectAccount={handleSelectAccount}
              error={error}
            />
          )}
          {!isLoading && page === 2 && (
            //  Account
            <div className="flex pt-2 flex-col space-y-10">
              <div className="space-y-3">
                <AccountPopUp
                  activeAccount={account}
                  clientAccounts={filterAccounts}
                  handleChangeAccount={handleChangeAccount}
                  accountPopUpOpen={isAccountPop}
                  setAccountPopUpOpen={setIsAccountPop}
                  title={"Select account"}
                >
                  <AccountDropdown activeAccount={account} />
                </AccountPopUp>

                {error && (
                  <div className="text-red-500 text-sm px-1">{error}</div>
                )}
              </div>

              <CommonButton onClick={handleBalanceCertificateRequest}>
                Continue
              </CommonButton>
            </div>
          )}
          {/* 
          {!isLoading && page === 3 && (
            //Enter OTP
            <EnterOTP
              handleOTP={handleOTP}
              otp={otp}
              setOtp={setOtp}
              error={error}
              setError={setError}
              resendDisabled={resendDisabled}
              counter={counter}
              handleResendCode={handleResendCode}
            />
          )} */}

          {/* page === 4 in case of OTP feature being used  */}
          {!isLoading && page === 3 && (
            //success message
            <div className="py-6">
              <SuccessMessage
                handleCloseButton={closeModal}
                title={"Balance Certificate Requested"}
                description={
                  "Your balance certificate request has been submitted successfully. You will receive an email shortly."
                }
              />
            </div>
          )}

          {isLoading || loadingProfiles ? <HangTightLoading /> : ""}
        </div>
      </Modal>
    </>
  );
};

export default BalanceCertificate;
