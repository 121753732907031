import React from "react";
import CommonButton from "../../Buttons/CommonButton";
import EmptyBeneficiaryIcon from "../../Icons/EmptyBeneficiaryIcon";
import AddBeneficiaryModal from "../../Modals/Beneficiary/AddBeneficiaryModal";

const EmptyBeneficiary = ({
  addBeneficiaryModalOpen,
  setaddBeneficiaryModalOpen,
  setRefreshBeneficiary,
}) => {
  return (
    <div className="flex flex-col items-center justify-center space-y-6">
      <EmptyBeneficiaryIcon className="w-40 h-40" />
      <span className="font-medium text-body-text text-xl px-6 text-center">
        Add new beneficiaries
      </span>
      <span className="font-medium text-paragraph-text text-sm px-6 text-center">
        You haven`t added any beneficiaries yet, but don`t miss out on the
        seamless transfers. let`s get you set up!
      </span>
      <div className="md:w-[400px] w-full items-center mx-auto">
        <AddBeneficiaryModal
          addBeneficiaryModalOpen={addBeneficiaryModalOpen}
          setaddBeneficiaryModalOpen={setaddBeneficiaryModalOpen}
          setRefreshBeneficiary={setRefreshBeneficiary}
        >
          <CommonButton>Add new beneficiary</CommonButton>
        </AddBeneficiaryModal>
      </div>
    </div>
  );
};

export default EmptyBeneficiary;
