import { useState } from "react";
import InputValidation from "../../middlewares/InputValidation";

const useForm = (initialValue, field="text") => {
  const [value, setValue] = useState(initialValue);
  const [error, setError] = useState("");

  const handleChange = (e) => {
    setValue(e.target.value);
    if (error)
      setError("")
  };

  const reset = () => {
    setValue("");
    setError("");
  };

  const validate = () => {
    let validate = InputValidation(value, field);
    setError(validate.message);
   return validate.hasError;
  };

  return {
    value,
    setValue,
    onChange: handleChange,
    reset,
    error,
    validate,
    setError,
  };
};

export default useForm;
