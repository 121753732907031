import React, { useState } from "react";
import Label from "../../Label";
import { ApprovedSign, DownArrowIcon } from "../../Icons";
import LoadingSpinner from "../../Icons/LoadingSpinner";

const CryptoNetworksDropdown = ({
  networks,
  value,
  onChange,
  label,
  id,
  placeholder,
  error,
  loading,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const handleSelect = (network) => {
    onChange(network);
    setIsOpen(false);
  };

  const getValue = () => {
    if (loading) {
      return (
        <span className="flex">
          <LoadingSpinner /> loading...
        </span>
      );
    } else if (error) {
      return error;
    } else if (!value) {
      return placeholder;
    } else {
      return value;
    }
  };

  return (
    <div className="relative w-full md:h-14 h-auto">
      <div
        onClick={() => setIsOpen(!isOpen)}
        className={`cursor-pointer border border-divider-border shadow-sm rounded-xl flex flex-col justify-between py-2 px-3
          ${
            error
              ? "border-highlighter-red focus-within:border-highlighter-red text-highlighter-red bg-highlighter-red/10"
              : "focus-within:border-primary-item text-body-text bg-body-"
          }
          `}
      >
        <Label
          htmlFor={id}
          className="block text-xs font-medium mb-1"
          text={label}
        />
        <div className="flex justify-between items-center">
          <span
            className={`text-sm ${
              value ? "font-medium" : "text-paragraph-text font-normal"
            }`}
          >
            {getValue()}
          </span>
          <div className="absolute inset-y-0 right-3 flex items-center">
            <DownArrowIcon className="h-5 w-5" />
          </div>
        </div>
      </div>
      {isOpen && (
        <ul className="absolute flex z-10 flex-col space-y-4 mt-2 w-full md:max-h-[260px] hide-scrollbar overflow-scroll md:w-[360px] h-auto bg-popup-bg border border-divider-border rounded-xl shadow-box-shadow p-2">
          {networks.map((network) => (
            <li
              key={network}
              className={`
                ${
                  network === value
                    ? "bg-active-state-bg"
                    : "hover:bg-hover-state-bg"
                }
                cursor-pointer px-4 py-3 text-sm font-medium text-body-text rounded-xl flex justify-between items-center`}
              onClick={() => handleSelect(network)}
            >
              <span>{network}</span>
              {network === value && <ApprovedSign />}
            </li>
          ))}
        </ul>
      )}
      {error && <span className="text-highlighter-red text-sm">{error}</span>}
    </div>
  );
};

export default CryptoNetworksDropdown;
