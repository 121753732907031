import React, { useState, useEffect } from "react";
import Label from "../../../../Label";
import { NavigationButton } from "../../../../Buttons";
import CommonButton from "../../../../Buttons/CommonButton";
import TransferGifs from "../partials/TransferGifs";
import FormInputField from "../../../../FormInputField";
import { useForm } from "../../../../../hooks";
import TransactionOTPModal from "../../../../Modals/TransactionsOTPModal";
import {
  exchangeTR,
  updateInternalTR,
} from "../../../../../redux/actions/TRrequest/Exchange";
import { useDispatch } from "react-redux";
import {
  FormatBalance,
  GetCurrenySymbol,
  GetValidationErrors,
} from "../../../../../helper";
import Review from "../../../Review";
import TransactionDetail from "../../../TransactionDetail";
import { verifyTROTP } from "../../../../../redux/actions/TRrequest/OTPVerification";
import { setPageTitle } from "../../../../../helper/metaDataHelper";
import { getTransactionDetails } from "../../../../../redux/actions/Transaction/TransactionHistory";
import { useNavigate } from "react-router-dom";
import { setActiveAccount } from "../../../../../redux/actions/home/ActiveAccountAction";
import SelectAccountDropdown from "../../../../Common/SelectAccountDropdown";
import ErrorMessage from "../../../../Common/ErrorMessage";

const Exchange = ({
  accounts,
  activeAccount,
  handlePrev,
  handleNext,
  setStep,
  step,
  setIsTransactionConfirmed,
  isTransferConfirmed,
  location,
}) => {
  const [loading, setLoading] = useState(false);
  const [trn_id, setTrnId] = useState("");
  const [isOTPModalOpen, setIsOTPModalOpen] = useState(false);
  const [listOfToAccounts, setListOfToAccounts] = useState([]);
  const [error, setError] = useState([]);

  const fromAccount = useForm(accounts[0]);
  const toAccount = useForm(accounts[1] ? accounts[1] : {});
  const amount = useForm("", "amount");
  const remarks = useForm("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    setPageTitle("Exchange Transfer");
  }, []);

  useEffect(() => {
    if (accounts.length) {
      const filteredToAccounts = accounts.filter(
        (account) => account.id !== fromAccount.value.id && !account.is_sepa
      );
      setListOfToAccounts(filteredToAccounts);

      if (fromAccount.value.id === toAccount.value.id) {
        toAccount.setValue(filteredToAccounts[0]);
      } else if (
        !filteredToAccounts.find((account) => account.id === toAccount.value.id)
      ) {
        toAccount.setValue(filteredToAccounts[0]);
      }
    }
    // eslint-disable-next-line
  }, [accounts, fromAccount.value, toAccount.value]);

  useEffect(() => {
    if (location.state) {
      // get transaction details
      const fetchTransactionDetails = async () => {
        const data = {
          trn_id: location.state.transaction.id,
          transaction_nature: "Internal",
        };
        setLoading(true);
        const res = await dispatch(getTransactionDetails(data));

        if (res.success) {
          const fromAccount = accounts.find(
            (account) => account.id === res.data.account_id
          );
          if (fromAccount) {
            fromAccount.setValue(fromAccount);
          }
          const ToAccount = accounts.find(
            (account) =>
              account.account_number === res.data.receiver_account_number
          );
          if (ToAccount) {
            toAccount.setValue(ToAccount);
          }
          amount.setValue(res.data.amount);
          remarks.setValue(res.data.remarks);
        }
        setLoading(false);
      };
      fetchTransactionDetails();
    }
  }, [location.state, dispatch]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleChangeFromAccount = async (account) => {
    fromAccount.setValue(account);
    if (error || fromAccount.error) {
      setError("");
      fromAccount.setError("");
    }
  };

  const handleChangeToAccount = async (account) => {
    toAccount.setValue(account);
    if (error || toAccount.error) {
      setError("");
      toAccount.setError("");
    }
  };

  const handleExchange = async () => {
    setLoading(true);
    setIsOTPModalOpen(true);
    const today = new Date();
    const formData = new FormData();
    formData.append("account_id", fromAccount.value.id);
    formData.append("account_number", toAccount.value.account_number);
    formData.append("amount", amount.value);
    formData.append("remarks", remarks.value);
    formData.append("transaction_date", today.toISOString().split("T")[0]);
    formData.append("is_exchange", true);

    let res;
    if (location.state) {
      formData.append("trn_id", location.state.transaction.id);
      res = await dispatch(updateInternalTR(formData));
    } else {
      res = await dispatch(exchangeTR(formData));
    }

    if (res.success === true) {
      setTrnId(res.data.trn_id);
      setLoading(false);
      setIsOTPModalOpen(true);
      setIsTransactionConfirmed(true);
      return;
    } else if (!res.success) {
      if (res.errors) {
        setError(GetValidationErrors(res.errors));
      } else {
        setError(res.error);
      }

      setStep(2);
    }
    setIsOTPModalOpen(false);
    setTimeout(() => {
      setLoading(false);
    }, 500);
  };

  const validateExchnage = () => {
    let validate = true;

    if (fromAccount.value.id === toAccount.value.id) {
      validate = false;
      setError("You can't exchange to the same account");
    }

    if (amount.value > fromAccount.value.balance) {
      validate = false;
      fromAccount.setError("Insufficient balance in this account.");
    }

    if (amount.validate()) {
      validate = false;
      amount.setError("Please enter a valid amount.");
    }

    if (!remarks.value) {
      validate = false;
      remarks.setError("Please enter a some remarks.");
    }

    setStep(2);

    return validate;
  };

  const handleContinue = () => {
    if (!validateExchnage()) return;
    handleNext();
  };

  const handleViewTransaction = async () => {
    setIsOTPModalOpen(false);
    setStep(1);
    navigate("/all-transactions");
    await dispatch(setActiveAccount(fromAccount.value));
  };

  return (
    <div className="min-h-screen">
      {step === 2 && (
        <div className="md:w-[400px] space-y-4">
          {error.length > 0 && (
            <ErrorMessage message={error} onClose={() => setError("")} />
          )}

          <div className="space-y-4">
            <div className="space-x-3">
              <span className="text-paragraph-text text-sm font-normal tracking-tight">
                You’re have
              </span>
              <span
                className={`text-body-text text-sm font-bold tracking-tight
                ${fromAccount.value.balance <= 0 && "text-highlighter-red"}
                `}
              >
                {FormatBalance(fromAccount.value.balance)}{" "}
                {fromAccount.value.currency_code}
              </span>
            </div>
            <div className="space-y-3">
              <Label
                className="text-body-text text-sm font-medium tracking-tight"
                text={"From Account"}
              />
              <SelectAccountDropdown
                title={"From account"}
                // accounts={accounts.filter(account => !account.is_sepa)}
                accounts={accounts}
                slectedAccount={fromAccount.value}
                setAccount={handleChangeFromAccount}
              />

              {fromAccount.error && (
                <span className="text-highlighter-red text-sm px-2">
                  {fromAccount.error}
                </span>
              )}
            </div>

            <TransferGifs />

            <div className="space-y-3">
              <Label
                className="text-body-text text-sm font-medium tracking-tight"
                text={"To Account"}
              />
              <SelectAccountDropdown
                title={"To account"}
                accounts={listOfToAccounts}
                slectedAccount={toAccount.value}
                setAccount={handleChangeToAccount}
              />
              {toAccount.error && (
                <span className="text-highlighter-red text-sm px-2">
                  {toAccount.error}
                </span>
              )}
            </div>

            <div className="space-y-3">
              <div>
                <FormInputField
                  label="Amount"
                  placeholder="Enter Amount"
                  type="number"
                  {...amount}
                  currency={GetCurrenySymbol(
                    (fromAccount.value.currency_code).split(" ")[0],
                    "13",
                    "13"
                  )}
                />
              </div>
              <div>
                <FormInputField
                  label="Remarks"
                  placeholder="Add Note"
                  type="text"
                  {...remarks}
                />
              </div>
            </div>
          </div>

          <hr className="border border-divider-border" />
          <div className="flex space-x-4">
            <NavigationButton text={"Back"} onClick={handlePrev} />
            <CommonButton
              disabled={!amount.value || !remarks.value}
              onClick={handleContinue}
            >
              Continue
            </CommonButton>
          </div>
        </div>
      )}
      {step === 3 && (
        <div className="md:w-[568px]">
          <Review
            handlePrev={handlePrev}
            handleTransaction={handleExchange}
            amount={`${amount.value} ${fromAccount.value.currency_code}`}
            message={` will get converted in to your ${toAccount.value.currency_code} account.`}
            isTransactionConfirmed={isTransferConfirmed}
          >
            <TransactionDetail
              label="Converting from"
              value={`${amount.value} ${fromAccount.value.currency_code} ${fromAccount.value.title}`}
            />
            <TransactionDetail
              label="Converting to"
              value={toAccount.value.title}
            />
            <hr className="flex w-full border-divider-border" />
            <TransactionDetail label="Nature" value="Exchange" />

            <hr className="w-full border-divider-border" />
            <TransactionDetail label="Remarks" value={remarks.value} />
          </Review>
        </div>
      )}
      <TransactionOTPModal
        isLoading={loading}
        setisLoading={setLoading}
        TrnId={trn_id}
        setTrnId={setTrnId}
        isModalOpen={isOTPModalOpen}
        setIsModalOpen={setIsOTPModalOpen}
        handleTransaction={handleExchange}
        reduxaction={verifyTROTP}
        navigateUrl="/"
        handleViewTransaction={handleViewTransaction}
      />
    </div>
  );
};

export default Exchange;
