import React, { useEffect, useState, useCallback } from "react";
import { checkAccountNumber } from "../../../../../../../redux/actions/TRrequest/AccountCheck";
import { useDispatch } from "react-redux";
import { CheckIcon } from "../../../../../../Icons";
import { GetValidationErrors } from "../../../../../../../helper";

const VerifyAccount = ({
  fromAccount,
  toAccountNumber,
  setBeneficiaryAccount,
  beneficiaryAccount,
  setError,
  error,
  location,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const verifyAccountNumber = useCallback(async () => {
    const { value, setError: setToAccountError } = toAccountNumber;

    if (!value || value.length < 1)
      return setToAccountError("Account number must be 10 digits");
    if (fromAccount.account_number === value)
      return setToAccountError("You can't transfer to the same account");

    setIsLoading(true);
    try {
      const verify = await dispatch(
        checkAccountNumber({ account_number: value })
      );

      if (verify.success) {
        verify.data.currency_id === fromAccount.currency_id
          ? setBeneficiaryAccount(verify.data)
          : setToAccountError(
              `Please enter ${fromAccount.currency_code} account number.`
            );
      } else {
        setError(
          verify.errors ? GetValidationErrors(verify.errors) : verify.error
        );
        setToAccountError(true);
      }
    } catch (error) {
      setError("An error occurred while verifying the account.");
    } finally {
      setIsLoading(false);
    }
  }, [dispatch, fromAccount, setBeneficiaryAccount, setError, toAccountNumber]);

  useEffect(() => {
    if (toAccountNumber.value.length === 12 && location?.state) {
      verifyAccountNumber();
    }
  }, [toAccountNumber.value, location?.state, verifyAccountNumber]);

  return (
    <>
      {isLoading ? (
        <div className="rounded-full">
          <img
            src="assets/gifs/loader.gif"
            className="w-6 h-6 rounded-full"
            alt="loader"
          />
        </div>
      ) : !beneficiaryAccount ? (
        <div
          onClick={verifyAccountNumber}
          className="px-3 py-1.5 cursor-pointer bg-secondary-icon-bg text-primary-item font-medium rounded-full hover:bg-hover-state-bg"
        >
          Verify
        </div>
      ) : (
        <div className="p-2 py-2.5 cursor-pointer bg-[#F1FAED] rounded-full">
          <CheckIcon className="text-active-state-bg" />
        </div>
      )}
    </>
  );
};

export default VerifyAccount;
