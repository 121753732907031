import React, { useState } from "react";
import Label from "../Label";
import { ApprovedSign, DownArrowIcon } from "../Icons";

const Dropdown = ({
  options,
  value,
  onChange,
  label,
  id,
  name,
  placeholder,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const handleSelect = (option) => {
    onChange({ target: { value: option.value, name } });
    setIsOpen(false);
  };

  const getDisplayLabel = () => {
    const selectedOption =
      options.length && options.find((option) => option.value === value);
    return selectedOption ? selectedOption.label : placeholder;
  };

  return (
    <div className="w-full md:h-14 h-auto">
      <div
        onClick={() => setIsOpen(!isOpen)}
        className="cursor-pointer relative bg-input-bg border border-divider-border shadow-sm rounded-xl flex flex-col justify-between py-2 px-3"
      >
        <Label
          htmlFor={id}
          className="block text-xs font-medium text-body-text mb-1"
          text={label}
        />
        <div className="flex justify-between items-center">
          <span
            className={`text-sm ${
              value
                ? "text-body-text font-medium"
                : "text-paragraph-text font-normal"
            }`}
          >
            {getDisplayLabel()}
          </span>
          <div className="absolute inset-y-0 right-3 flex items-center">
            <DownArrowIcon className="h-5 w-5" />
          </div>
        </div>
      </div>
      {isOpen && (
        <ul className="absolute flex z-[8] flex-col space-y-1 mt-2 md:max-h-[260px] hide-scrollba custom-scrollbar overflow-y-scroll md:w-[360px] w-[330px] h-auto bg-popup-bg border border-divider-border rounded-xl shadow-box-shadow p-2">
          {options.map((option) => (
            <li
              key={option.value}
              className={`
                ${
                  option.value === value
                    ? "bg-active-state-bg"
                    : "hover:bg-hover-state-bg"
                }
                cursor-pointer px-4 py-3 text-sm font-medium text-body-text rounded-xl flex justify-between items-center`}
              onClick={() => handleSelect(option)}
            >
              <span>{option.label}</span>
              {option.value === value && <ApprovedSign />}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default Dropdown;
