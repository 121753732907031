import React from "react";
import Label from "../Label";

const FormTextAreaField = ({
  label,
  name,
  id,
  rows,
  cols,
  placeholder,
  value,
  onChange,
  readOnly,
  error,
}) => {
  return (
    <>
      <div
        className={`w-full px-3 py-2 relative rounded-xl shadow-sm ${
          readOnly
            ? "read-only:bg-secondary-icon-bg cursor-pointer"
            : error
            ? "border-highlighter-red bg-highlighter-red/10 border focus-within:border-highlighter-red focus-within:bg-highlighter-red/10"
            : "bg-input-bg border border-divider-border focus-within:border-primary-item "
        }`}
      >
        <Label
          htmlFor={id}
          className="block text-xs font-medium text-body-text"
          text={label}
        />
        <textarea
          name={name}
          id={id}
          rows={rows}
          cols={cols}
          value={value}
          onChange={onChange}
          className="block w-full bg-transparent border-0 p-0 text-sm font-medium text-body-text placeholder-paragraph-text placeholder:font-normal focus:ring-0"
          placeholder={placeholder}
        />
      </div>
      {error && <span className="text-highlighter-red text-sm">{error}</span>}
    </>
  );
};

export default FormTextAreaField;
