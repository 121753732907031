import React, { useState, useEffect } from "react";
import ProgressBar from "../../../components/ProgessBar";
import Label from "../../../components/Label";
import { useDispatch, useSelector } from "react-redux";
import CancelTransactionModal from "../../../components/Modals/CancelTransactionModal";
import { SmallButton } from "../../../components/Buttons";
import Steps from "../../../components/Common/Steps";
import TransferType from "../../../components/Transactions/Transfer/TransferType";
import CommonButton from "../../../components/Buttons/CommonButton";
import Exchange from "../../../components/Transactions/Transfer/TransferDetails/Exchange";
import InternalTransfer from "../../../components/Transactions/Transfer/TransferDetails/InternalTransfer";
import ExternalTransfer from "../../../components/Transactions/Transfer/TransferDetails/ExternalTransfer";
import EmptyExchange from "../../../components/Empty/EmptyExchange";
import { useLocation, useNavigate } from "react-router-dom";
import { setPageTitle } from "../../../helper/metaDataHelper";
import { scrollPage } from "../../../helper/ScrollHelper";
import { setActiveAccount } from "../../../redux/actions/home/ActiveAccountAction";
import { getModuleStatus } from "../../../redux/actions/lookups/getModuleStatus";

const Transfer = () => {
  const [step, setStep] = useState(1);
  const labels = ["Transfer type", "Transfer details", "Review"];
  // this need to be fixed from api side to get only the fiat accounts
  const fiatAccounts = useSelector((state) =>
    state.client.clientInfo.accounts.filter(
      (account) => account.is_crypto === 0 || account.is_crypto === null
    )
  );
  const { activeAccount } = useSelector((state) => state.activeAccount);
  const [transferType, setTransferType] = useState("");
  // const [loading, setLoading] = useState(false);
  // const [isOTPModalOpen, setIsOTPModalOpen] = useState(false);
  // const [trn_id, setTrnId] = useState("");

  const [isTransferConfirmed, setIsTransactionConfirmed] = useState(false);
  const [beneficiary, setBeneficiary] = useState({});
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleNext = () => {
    if (step < labels.length + 1) setStep(step + 1);
  };

  const handlePrev = () => {
    if (step === 2 && location.state) {
      navigate(location.pathname, { state: null });
    }
    if (step > 1 && !isTransferConfirmed) {
      setStep(step - 1);
      scrollPage(-500);
    }
  };
  let resetPageTitle = step === 1;

  useEffect(() => {
    setPageTitle("Transfer");
    dispatch(getModuleStatus({ module: "OpenPayd" }));
    // eslint-disable-next-line
  }, [resetPageTitle]);

  useEffect(() => {
    if (location.state) {
      const { beneficiary, transaction, type } = location.state;
      if (type === "transfer" && activeAccount.is_crypto) {
        dispatch(setActiveAccount(fiatAccounts[0]));
      }
      if (beneficiary && beneficiary.id) {
        setBeneficiary(beneficiary);
        setTransferType("external-transfer");
        setStep(2);
      }

      if (transaction) {
        switch (transaction.nature) {
          case "Internal":
            if (transaction.exchange) {
              setTransferType("exchange");
            } else {
              setTransferType("internal-transfer");
            }
            setStep(2);
            break;
          case "TT":
            setTransferType("external-transfer");
            setStep(2);
            break;
          default:
            break;
        }
      }
    }
    // eslint-disable-next-line
  }, [location.state]);

  return (
    <div className="max-w-[992px] mx-auto min-h-screen">
      <div className="inline-flex space-x-2 px-2 pb-8">
        <CancelTransactionModal contextLabel="Cancel Transfer">
          <SmallButton text={"Cancel Transfer"} cancel={true} />
        </CancelTransactionModal>
      </div>

      <div className="flex flex-col md:items-center md:justify-center">
        <Steps step={step} labels={labels} />
        <ProgressBar step={step} labels={labels} />
      </div>

      <div className="flex flex-col px-3 md:items-center justify-center md:p-6">
        <div className="py-4">
          {/* Transfer Types */}

          {step === 1 && (
            <div className="md:w-[400px] space-y-4">
              <div>
                <Label
                  className="text-body-text text-sm font-medium tracking-tight"
                  text={"Select Transfer Type"}
                />
              </div>
              <TransferType type={transferType} setType={setTransferType} />
              <hr className="border border-divider-border" />
              <CommonButton disabled={!transferType} onClick={handleNext}>
                Continue
              </CommonButton>
            </div>
          )}

          {step > 1 && (
            <div className="space-y-4">
              {transferType === "exchange" && (
                <>
                  {fiatAccounts.filter(account => !account.is_sepa).length > 1 ? (
                    <Exchange
                      accounts={fiatAccounts.filter(account => !account.is_sepa)}
                      activeAccount={activeAccount}
                      handleNext={handleNext}
                      handlePrev={handlePrev}
                      step={step}
                      setStep={setStep}
                      setIsTransactionConfirmed={setIsTransactionConfirmed}
                      isTransferConfirmed={isTransferConfirmed}
                      location={location}
                    />
                  ) : (
                    <EmptyExchange />
                  )}
                </>
              )}
              {transferType === "internal-transfer" && (
                <InternalTransfer
                  accounts={fiatAccounts}
                  activeAccount={activeAccount}
                  handleNext={handleNext}
                  handlePrev={handlePrev}
                  step={step}
                  setStep={setStep}
                  setIsTransactionConfirmed={setIsTransactionConfirmed}
                  isTransferConfirmed={isTransferConfirmed}
                  location={location}
                />
              )}
              {transferType === "external-transfer" && (
                <ExternalTransfer
                  accounts={fiatAccounts}
                  activeAccount={activeAccount}
                  handleNext={handleNext}
                  handlePrev={handlePrev}
                  step={step}
                  setStep={setStep}
                  setIsTransactionConfirmed={setIsTransactionConfirmed}
                  isTransferConfirmed={isTransferConfirmed}
                  beneficiary={beneficiary}
                  location={location}
                />
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Transfer;
