import React from "react";
import { DownArrowIcon } from "../../../../Icons";
import { useSelector } from "react-redux";

const ActiveAccount = ({ activeAccount }) => {
  const { name } = useSelector((state) => state.ownCompany.company);
  return (
    <>
      {activeAccount && (
        <div className="flex p-[6px] pr-4 lg:w-[338px] max-w-[338px] max-[360px]:max-w[300px] justify-between items-center bg-white dark:bg-primary-Icon-bg rounded-[100px] cursor-pointer hover:bg-hover-state-bg dark:hover:bg-active-state-bg">
          <div className="flex items-center pr-12">
            <img
              src={activeAccount?.logo}
              alt="flag"
              className="w-10 h-10 rounded-full"
            />
            <div className="px-2">
              <p className="dark:text-body-text text-body-text text-sm font-medium leading-5 tracking-[0.07px] ">
                {activeAccount.account_number}
              </p>
              <p className="dark:text-body-text text-body-text font-normal text-sm leading-5 tracking-[0.07px] md:flex-nowrap line-clamp-1">
                {activeAccount.currency_code} · {name}
              </p>
            </div>
          </div>
          <DownArrowIcon className="h-5 w-5 pr-5" />
        </div>
      )}
    </>
  );
};

export default ActiveAccount;
