import React, { useState, useRef, useEffect } from "react";
import { DownArrowIcon, SearchIcons } from "../../Icons";

const CurrenciesInput = ({
  currencies,
  SelectedCurrency,
  onChange,
  placeholder,
  label,
  error,
  children,
}) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const searchInputRef = useRef(null);

  const handleSelect = (option) => {
    onChange(option);
    setIsOpen(false);
    setSearchQuery("");
  };

  const filteredCurrencies = currencies ? currencies.filter(
    (country) =>
      country.code.toLowerCase().includes(searchQuery.toLowerCase()) ||
      country.name.toLowerCase().includes(searchQuery.toLowerCase())
  ) : currencies;

  const handleKeyDown = (e, countryItem) => {
    switch (e.key) {
      case "Enter":
        e.preventDefault();
        handleSelect(countryItem);
        break;
      case "ArrowDown":
        e.preventDefault();
        const nextElement = e.target.nextElementSibling;
          if (nextElement) {
            nextElement.focus();
          }
        break;
      case "ArrowUp":
        e.preventDefault();
        const prevElement = e.target.previousElementSibling;
        if (prevElement) {
          prevElement.focus();
        }
        break;
      default:
        break;
    }
  };

  const handleSearchKeyDown = (e) => {
    if (e.key === "ArrowDown"  && e.target === searchInputRef.current) {
      const firstElement = dropdownRef.current.querySelector("li[tabindex='0']");
      if (firstElement) {
        firstElement.focus();
      }
    }
  };

  // Handle click outside dropdown
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  return (
    <div className="relative" ref={dropdownRef}>
      <div
        tabIndex={0}
        onKeyDown={() => setIsOpen(!isOpen)}
        onClick={() => setIsOpen(!isOpen)}
        className={`w-full cursor-pointer shadow-sm rounded-xl flex justify-between items-center py-2.5 px-3 focus:border-primary-item outline-none
          ${ error
            ? "border-highlighter-red bg-highlighter-red/10 border focus-within:border-highlighter-red focus-within:bg-highlighter-red/10"
            : "bg-input-bg border border-divider-border focus-within:border-icon-btn focus:bg-input-active-bg"
          }
          
          `}
      >
        {SelectedCurrency ? (
          children
        ) : (
          <div className="flex justify-between w-full items-center">
            <div>
              <p className={"block text-xs font-medium text-body-text"}>
                {label}
              </p>
              <p className="text-paragraph-text text-xs">{placeholder}</p>
            </div>
            <DownArrowIcon className="w-4 h-4 mr-2" />
          </div>
        )}
      </div>

      {isOpen && (
        <ul className="absolute flex z-10 flex-col space-y-4 mt-2 w-[340px] max-h-[260px] hide-scrollbar overflow-scroll md:w-[360px] h-auto bg-popup-bg border border-divider-border rounded-xl shadow-box-shadow p-2">
          <li>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                if (filteredCurrencies.length > 0) {
                    handleSelect(filteredCurrencies[0]);
                }
                return;
              }}
            >
              <div className="max-w-full px-3 py-1 bg-search-bg rounded-full flex justify-start items-center">
                <SearchIcons className="w-6 h-6 relative" />
                <input
                  ref={searchInputRef}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  value={searchQuery}
                  placeholder="Search"
                  autoFocus={true}
                  className="w-full text-paragraph-text text-sm font-normal bg-transparent border-none focus:ring-0 tracking-tight"
                  onKeyDown={handleSearchKeyDown}
                />
              </div>
            </form>
          </li>

          {filteredCurrencies.length === 0 ? (
            <li className="text-center text-sm text-body-text">
              No currencies found
            </li>
          ) : (
            filteredCurrencies.map((currencyItem) => (
              <li
                tabIndex={0}
                key={currencyItem.id}
                className={`${
                  currencyItem.id === SelectedCurrency?.id &&
                  "dark:bg-option-list-selected bg-active-state-bg"
                } flex justify-between cursor-pointer px-4 py-3 text-sm font-medium text-body-text hover:bg-hover-state-bg rounded-xl bg-transparent outline-none focus:outline-primary-item focus:outline-2 focus:bg-hover-state-bg items-center`}
                onClick={() => handleSelect(currencyItem)}
                onKeyDown={(e) => handleKeyDown(e, currencyItem)}
              >
                <div className="flex space-x-3 items-center">
                  <img
                    className="rounded-full w-8 h-8"
                    src={currencyItem.logo}
                    alt="logo"
                  />
                  <span>{currencyItem.name}  {currencyItem.code}</span>
                </div>
              </li>
            ))
          )}
        </ul>
      )}
    </div>
  );
};

export default CurrenciesInput;
