import { CommonActionTypes } from "../../action-types";

const currencies = JSON.parse(localStorage.getItem("currencies"));

const initialState = {
  currencies: currencies ? currencies : null
};

export default function allCurrenciesReducer(state = initialState, action) {
  switch (action.type) {
    case CommonActionTypes.GET_CURRENCIES:
      return {
        currencies: action.payload,
      };
    default:
      return state;
  }
}
