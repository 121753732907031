import axios from "axios";
import { kycUserInterceptor } from "../Common/interceptor";

const API_URL = process.env.REACT_APP_API_BASE_URL;
const apiKey = process.env.REACT_APP_API_KEY;

const KycUserService = axios.create({
  baseURL: API_URL,
  headers: {
    "Content-Type": "multipart/form-data",
    "api-key": apiKey,
  },
});

KycUserService.interceptors.request.use(kycUserInterceptor);

export default KycUserService;