import { configureStore } from "@reduxjs/toolkit";
import authReducer from "../reducers/auth";
import { commonReducer } from "../reducers/common";
import clientInfoReducer from "../reducers/home";
import activeAccountReducer from "../reducers/activeaccount";
import userInfoReducer from "../reducers/userInfo/idnex";
import notificationReducer from "../reducers/notification";
import clientCardsReducer from "../reducers/card";
import activeCardReducer from "../reducers/activeCards";
import ownCompanyReducer from "../reducers/ownCompany";
import allCountriesReducer from "../reducers/allCountries";
import sessionReducer from "../reducers/sessionReducer";
import moduleReducer from "../reducers/modules";
import eligibleCurrenciesReducer from "../reducers/elibibleCurrencies";
import clientProfilesReducer from "../reducers/clientProfiles";
import cardTransactions from "../reducers/cardTransactions";
import clientHelpReasonsReducer from "../reducers/clientHelpReasons";
import allCurrenciesReducer from "../reducers/allCurrencies";

const store = configureStore({
  reducer: {
    user: authReducer,
    common: commonReducer,
    client: clientInfoReducer,
    activeAccount: activeAccountReducer,
    userInfo: userInfoReducer,
    notifications:notificationReducer,
    clientCards: clientCardsReducer,
    activeCard: activeCardReducer,
    ownCompany: ownCompanyReducer,
    allCountries: allCountriesReducer,
    session: sessionReducer,
    modules: moduleReducer,
    eligibleCurrencies: eligibleCurrenciesReducer,
    clientProfiles: clientProfilesReducer,
    cardTransactions: cardTransactions,
    clientHelpReasons: clientHelpReasonsReducer,
    allCurrencies: allCurrenciesReducer,
  },
});

export default store;
