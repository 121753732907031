/* Removing the OTP verification for now. Keeping the code commented out for future use. */
import React, { useState } from "react";
import Modal from "../../../Common/Modal";
import { useDispatch } from "react-redux";
import SuccessMessage from "../../../Common/SuccessMessage";
import HangTightLoading from "../../../Common/HangTightLoading";
import ClientAccountsList from "../../../Common/SelectAccountList";
import { applyForNewCard } from "../../../../redux/actions/ServiceRequests/newCard";
import useClientProfiles from "../../../../hooks/useClientProfiles";
// import EnterOTP from "../../../Common/EnterOTP";
// import { serviceRequestVerifyOTP } from "../../../../redux/actions/ServiceRequests/serviceRequestVerifyOTP";

const AddNewCardModal = ({ children }) => {
  const { clientProfiles, loadingProfiles } = useClientProfiles();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const [error, setError] = useState("");
  const [page, setPage] = useState(1);
  const dispatch = useDispatch();

  // const [otp, setOtp] = useState(["", "", "", "", "", ""]);
  // const [requestId, setRequestId] = useState("");
  // const [resendDisabled, setResendDisabled] = useState(true);
  // const [counter, setCounter] = useState(60);

  // useEffect(() => {
  //   if (!isLoading && resendDisabled) {
  //     const timer = setInterval(() => {
  //       setCounter((prevCounter) => {
  //         if (prevCounter === 1) {
  //           setResendDisabled(false);
  //           clearInterval(timer);
  //           return 0;
  //         }
  //         return prevCounter - 1;
  //       });
  //     }, 1000);

  //     return () => clearInterval(timer);
  //   }
  // }, [isLoading, resendDisabled]);

  // const handleOTP = async () => {
  //   const data = {
  //     request_id: requestId,
  //     otp: otp.join(""),
  //   };
  //   setIsLoading(true);
  //   const res = await dispatch(serviceRequestVerifyOTP(data));
  //   if (res.success) {
  //     setRequestId("");
  //     setPage(3);
  //   } else {
  //     setError(res.error);
  //   }
  //   setIsLoading(false);
  //   setOtp(["", "", "", "", "", ""]);
  // };

  // const handleResendCode = () => {
  //   setCounter(60);
  //   setResendDisabled(true);
  //   handleAddNewCard();
  // };

  const handleOnCloseModal = () => {
    setIsOpen(false);
    setTimeout(() => {
      setSelectedAccount({});
      setError("");
      setPage(1);
      // setOtp(["", "", "", "", "", ""]);
      // setRequestId("");
    }, 300);
  };

  const handleAddNewCard = async () => {
    setIsLoading(true);
    const data = {
      client_id: selectedAccount.id,
    };
    const res = await dispatch(applyForNewCard(data));
    if (res.success) {
      // setRequestId(res.data.request_id);
      setPage(2);
      // setCounter(60);
    } else if (!res.success) {
      setError(res.error);
    }
    setIsLoading(false);
  };

  return (
    <>
      <div onClick={() => setIsOpen(true)}>{children}</div>
      <Modal
        isOpen={isOpen}
        handleOnCloseModal={handleOnCloseModal}
        //  page === 3 in case of OTP feature being used
        header={page !== 2 && !isLoading && !loadingProfiles}
        headerTitle={"Add new card"}
        // headerTitle={page === 2 ? "Enter OTP" : "Add new card"}
        loading={isLoading}
        backButton={page === 2}
        // backButton={page === 2 ? true : false}
        backButtonAction={() => setPage(1)}
        // backButtonAction={() => (page > 1 ? setPage(page - 1) : setPage(1))}
        className="bg-popup-bg w-[432px] max-h-[578px] overflow-scroll hide-scrollbar shadow-xl rounded-3xl"
      >
        {!isLoading && page === 1 && clientProfiles && (
          <div className="pb-4">
            <div className="h-[165px] bg-body-bg">
              <img
                src="assets/logos/PopUpCardBg.png"
                alt="Card"
                className="px-8 pt-3"
              />
            </div>
            <div className="pt-3 px-6 max-[360px]:px-4">
              {error && (
                <span className="text-highlighter-red text-sm font-normal">
                  {error}
                </span>
              )}
              <ClientAccountsList
                clientsAccounts={clientProfiles}
                CloseAddAcccount={selectedAccount}
                setCloseAddAccount={setSelectedAccount}
                handleSelectAccount={handleAddNewCard}
              />
            </div>
          </div>
        )}

        {/* {!isLoading && page === 2 && (
          //Enter OTP
          <div className="px-6 max-[360px]:px-4">
            <EnterOTP
              handleOTP={handleOTP}
              otp={otp}
              setOtp={setOtp}
              error={error}
              setError={setError}
              resendDisabled={resendDisabled}
              counter={counter}
              handleResendCode={handleResendCode}
            />
          </div>
        )} */}

        {/* page === 3 in case of OTP feature being used */}
        {!isLoading && page === 2 && (
          <div className="p-6 max-[360px]:px-4">
            <SuccessMessage
              title={"New card request sent"}
              description={
                <p>
                  You will receive an update within <br /> 2-3 working days.
                </p>
              }
              handleCloseButton={handleOnCloseModal}
            />
          </div>
        )}

        {isLoading || loadingProfiles ? (
          <HangTightLoading message={"We are placing your request!"} />
        ) : null}
      </Modal>
    </>
  );
};

export default AddNewCardModal;
