const FilterCurrencies = (currencies, filterType) => {
  switch (filterType) {
    case "exclude_fiat":
      return currencies.filter(
        (currency) =>
          !["HKD", "RUB", "GBP"].includes(currency.code) &&
          currency.is_crypto !== 1
      ).sort((a, b) => a.code.localeCompare(b.code));
    case "crypto":
      return currencies.filter((currency) => currency.is_crypto === 1).sort((a, b) => a.code.localeCompare(b.code));
    case "fiat":
      return currencies.filter((currency) => currency.is_crypto !== 1).sort((a, b) => a.code.localeCompare(b.code));
    default:
      return currencies;
  }
};

export default FilterCurrencies;
