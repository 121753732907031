import React from "react";
import Label from "../Label";
import Input from "../Input";

const FormInputField = ({
  label,
  name,
  id,
  type,
  placeholder,
  icon,
  value,
  onChange,
  readOnly,
  currency,
  isDisabled,
  maxLength,
  required,
  error,
}) => {
  return (
    <>
      <div
        className={`w-full px-3 py-2 relative rounded-xl  shadow-sm ${
          readOnly
            ? "read-only:bg-secondary-icon-bg cursor-pointer"
            : error
            ? "border-highlighter-red bg-highlighter-red/10 border focus-within:border-highlighter-red focus-within:bg-highlighter-red/10"
            : "bg-input-bg border border-divider-border focus-within:border-icon-btn focus:bg-input-active-bg"
        }`}
      >
        <Label
          htmlFor={id}
          className={`block text-xs font-medium ${
            isDisabled ? "text-paragraph-text" : "text-paragraph-text"
          }`}
          text={label}
        />
        <div className="flex items-center space-x-1">
          {currency && (
            <span className="text-sm text-body-text">{currency}</span>
          )}
          <Input
            type={type}
            name={name}
            id={id}
            value={value}
            onChange={onChange}
            placeholder={placeholder}
            min="1"
            className={`block no-arrows autofill-input w-full bg-transparent border-0 p-0 text-sm font-medium ${
              isDisabled ? "text-paragraph-text" : "text-body-text"
            } ${
              readOnly ? "cursor-pointer" : "cur"
            } placeholder-placeholder-text placeholder:font-normal focus:ring-0`}
            isDisabled={isDisabled}
            readOnly={readOnly}
            maxLength={maxLength}
            required={required}
          />
          {icon && (
            <div className="absolute inset-y-0 right-0 flex items-center pr-3 hover:cursor-pointer">
              {icon}
            </div>
          )}
        </div>
      </div>
      {error && <span className="text-highlighter-red text-sm">{error}</span>}
    </>
  );
};

export default FormInputField;
