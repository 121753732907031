import React, { useState } from "react";
import Modal from "../../Common/Modal";
import BankDetailsInput from "./partials";
import Button from "../../Button";
import { useSelector } from "react-redux";
import CopyToClipboard from "../../../helper/CopyToClipboard";

const BankDetailsModal = ({ children }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { activeAccount } = useSelector((state) => state.activeAccount);

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const [copiedText, setCopiedText] = useState("");
  const [isCopied, setIsCopied] = useState(false);
  const [message, setIsMessage] = useState("");

  const handleCopy = (text, field) => {
    CopyToClipboard(
      text,
      setCopiedText,
      setIsCopied,
      setIsMessage,
      `${field} copied`
    );
  };

  const handleCopyAll = () => {
    let allDetails = "";
    if (activeAccount.is_sepa) {
      allDetails = `
      Beneficiary name: ${activeAccount.title}\n
      Bank Name: ${activeAccount.bank_name}\n
      Account number: ${activeAccount.account_number}\n
      BIC: ${activeAccount.iban}\n
      IBAN: ${activeAccount.bic}\n
      Currency: ${activeAccount.currency_code}\n
      Bank Address: ${activeAccount.bank_address}
      `;
    } else {
      allDetails = `
      Beneficiary name: ${activeAccount.title}\n
      Account number: ${activeAccount.account_number}
      `;
    }

    CopyToClipboard(
      allDetails,
      setCopiedText,
      setIsCopied,
      setIsMessage,
      "Bank details copied"
    );
  };
  return (
    <>
      <div onClick={() => setIsModalOpen(true)}>{children}</div>
      <Modal
        isOpen={isModalOpen}
        handleOnCloseModal={closeModal}
        header={true}
        headerTitle={"Bank Details"}
        className="bg-popup-bg w-full max-w-[432px] py-4 overflow-scroll hide-scrollbar shadow-xl rounded-3xl mx-auto"
      >
        {activeAccount.is_sepa ? (
          <div className="flex flex-col md:px-6 px-4 py-3 space-y-4 ">
            <BankDetailsInput
              label="Beneficiary name"
              value={activeAccount.bank_account_holder_name}
              name="beneficiaryname"
              onCopy={() =>
                handleCopy(
                  activeAccount.bank_account_holder_name,
                  "Beneficiary name"
                )
              }
              isCopied={
                copiedText === activeAccount.bank_account_holder_name &&
                isCopied
              }
            />
            <BankDetailsInput
              label="Bank name"
              value={activeAccount.bank_name}
              name="bankName"
              onCopy={() => handleCopy(activeAccount.bank_name, "Bank name")}
              isCopied={copiedText === activeAccount.bank_name && isCopied}
            />
            <BankDetailsInput
              label="Account number"
              value={activeAccount.account_number}
              name="accountNumber"
              onCopy={() =>
                handleCopy(activeAccount.account_number, "Account number")
              }
              isCopied={copiedText === activeAccount.account_number && isCopied}
            />
            <BankDetailsInput
              label="IBAN"
              value={activeAccount.iban}
              name="iban"
              onCopy={() => handleCopy(activeAccount.iban, "IBAN")}
              isCopied={copiedText === activeAccount.iban && isCopied}
            />
            <BankDetailsInput
              label="BIC code"
              value={activeAccount.bic}
              name="bic"
              onCopy={() => handleCopy(activeAccount.bic, "BIC code")}
              isCopied={copiedText === activeAccount.bic && isCopied}
            />
            <BankDetailsInput
              label="Currency code"
              value={activeAccount.currency_code}
              name="currency"
              onCopy={() =>
                handleCopy(activeAccount.currency_code, "Currency code")
              }
              isCopied={copiedText === activeAccount.currency_code && isCopied}
            />
            <BankDetailsInput
              label="Bank Address"
              value={activeAccount.bank_address}
              name="bankAddress"
              onCopy={() =>
                handleCopy(activeAccount.bank_address, "Bank Address")
              }
              isCopied={copiedText === activeAccount.bank_address && isCopied}
            />
          </div>
        ) : (
          <div className="flex flex-col md:px-6 px-4 py-3 space-y-4 ">
            <BankDetailsInput
              label="Beneficiary name"
              value={activeAccount.title}
              name="beneficiaryname"
              onCopy={() => handleCopy(activeAccount.title, "Beneficiary name")}
              isCopied={copiedText === activeAccount.title && isCopied}
            />
            <BankDetailsInput
              label="Account number"
              value={activeAccount.account_number}
              name="accountNumber"
              onCopy={() =>
                handleCopy(activeAccount.account_number, "Account number")
              }
              isCopied={copiedText === activeAccount.account_number && isCopied}
            />
          </div>
        )}
        <div className="flex flex-col items-center px-4 mt-6">
          <Button
            handleClick={handleCopyAll}
            className="md:w-[384px] w-full px-3 py-4 bg-button-bg text-sm text-button-text font-medium rounded-[100px]
          hover:bg-button-hover-bg"
            title="Copy all details"
          />
        </div>
        {message && (
          <div
            className="absolute bottom-2 left-1/2 transform -translate-x-1/2 w-auto px-4 py-2 border border-transparent
           dark:border-divider-border rounded-lg bg-[#0F0F0F] shadow-md"
          >
            <p className="text-white text-sm font-normal tracking-[0.07px]">
              {message}
            </p>
          </div>
        )}
      </Modal>
    </>
  );
};

export default BankDetailsModal;
