const ManualIcon = ({ className }) => {
  return (
    <div className={className}>
      <svg
        className="stroke-primary-item"
        fill="none"
        strokeWidth="2"
        viewBox="0 0 24 24"
        strokeLinecap="round"
        strokeLinejoin="round"
        height="24px"
        width="24px"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M12 7v14"></path>
        <path d="M16 12h2"></path>
        <path d="M16 8h2"></path>
        <path d="M3 18a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h5a4 4 0 0 1 4 4 4 4 0 0 1 4-4h5a1 1 0 0 1 1 1v13a1 1 0 0 1-1 1h-6a3 3 0 0 0-3 3 3 3 0 0 0-3-3z"></path>
        <path d="M6 12h2"></path>
        <path d="M6 8h2"></path>
      </svg>
    </div>
  );
};

export default ManualIcon;
