import commonService from "../../../services/Common/service";
import {
  AuthActionTypes,
  ClientProfilesActionTypes,
  CommonActionTypes,
  ElgibleCurrenciesActions,
  UserInfoActionTypes,
} from "../../action-types";
import { startSessionExpirationTimers } from "../Session";

export const login = (user) => async (dispatch) => {
  dispatch({ type: CommonActionTypes.PENDING, payload: true });

  try {
    const checkMFA = process.env.REACT_APP_MFA === "true" ? true : false;
    if (checkMFA) {
      user.from_web = true;
    }
    const res = await commonService.post("/user/web-login", user);

    // if (res.data.success && res.data.token) {
    //   localStorage.setItem("user", JSON.stringify(res.data));
    // }
    // wait for the api, to be refactor for Rejected clients success from false to true"
    if (res.data.token && !checkMFA) {
      localStorage.setItem("user", JSON.stringify(res.data));
      dispatch({ type: AuthActionTypes.LOGIN, payload: res.data });
      // Set the session expiration timers
      dispatch(startSessionExpirationTimers());
    }

    return res.data;
  } catch (err) {
    const message = err.response.data.message || err.message;

    dispatch({
      type: CommonActionTypes.REJECTED,
      payload: { message, isError: true },
    });
    return err.response.data;
  } finally {
    dispatch({ type: CommonActionTypes.RESET, payload: true });
  }
};

export const userLogin = (user) => async (dispatch) => {
  try {
    let res = await commonService.post("/user/admin-login", user);

    if (res.data.token) {
      localStorage.setItem("kyc_user", JSON.stringify(res.data));
    }
    return res.data;
  } catch (err) {
    return err.response.data;
  }
};

export const logout = () => async (dispatch) => {
  dispatch({ type: CommonActionTypes.PENDING, payload: true });

  try {
    // dispatch({ type: AuthActionTypes.LOGOUT });
    await commonService.post("/user/logout");
  } catch (err) {
    const message = err.response.data.message || err.message;

    dispatch({
      type: CommonActionTypes.REJECTED,
      payload: { message, isError: true },
    });
  } finally {
    ResetReduxState(dispatch);
    return { success: true };
  }
};

export const UpdateRequestedKYC = (updatedUser) => {
  localStorage.setItem("user", JSON.stringify(updatedUser));
  return {
    type: AuthActionTypes.UPDATE_REQUESTED_KYC,
    payload: updatedUser.kyc_requested,
  };
};

export const ImpersonateUser = (data) => async (dispatch) => {
  dispatch({ type: CommonActionTypes.PENDING, payload: true });

  try {
    const res = await commonService.post("/user/impersonate", data);

    if (res.data.token) {
      let user = res.data;
      user.impersonator = data.impersonator;
      user.impersonated = data.impersonated;
      user.key = data.key;
      user.isSwitch = true;

      localStorage.setItem("user", JSON.stringify(user));
      dispatch({ type: AuthActionTypes.LOGIN, payload: user });

      // Set the session expiration timers
      dispatch(startSessionExpirationTimers());
    }

    return res.data;
  } catch (err) {
    const message = err.response.data.message || err.message;

    dispatch({
      type: CommonActionTypes.REJECTED,
      payload: { message, isError: true },
    });
    return err.response.data;
  } finally {
    dispatch({ type: CommonActionTypes.RESET, payload: true });
  }
};

export const SwitchUser = (data) => async (dispatch) => {
  const switchUserUrl = `${process.env.REACT_APP_FRIENDLY_WEB_ADMIN_URL}${process.env.REACT_APP_SWITCH_ADMIN_PATH}?key=${data.key}&impersonator=${data.impersonator}&impersonated=${data.impersonated}`;
  ResetReduxState(dispatch);
  window.location.href = switchUserUrl;
  console.log("switchUserUrl", switchUserUrl);

  return { success: true };
};

const ResetReduxState = (dispatch) => {
  dispatch({ type: "RESET-ALL" });
  dispatch({ type: AuthActionTypes.LOGOUT });
  dispatch({
    type: ClientProfilesActionTypes.GET_ALL_CLIENT_PROFILES,
    payload: [],
  });
  dispatch({
    type: ElgibleCurrenciesActions.GET_ELIGIBLE_CURRENCIES,
    payload: [],
  });
  dispatch({
    type: UserInfoActionTypes.USER_INFO,
    payload: null,
  });
  dispatch({ type: CommonActionTypes.RESET, payload: true });
};
