import { CommonActionTypes } from "../../action-types";
import FormDataService from "../../../services/FormDataService";
import commonService from "../../../services/Common/service";
import KycUserService from "../../../services/KycUserService";

export const uploadPassportClientKYC = (data, flag = false) => async (dispatch) => {
    dispatch({ type: CommonActionTypes.PENDING, payload: true });

    try {
      const res = flag
        ? await KycUserService.post("/user/client-kyc/upload-passport", data)
        : await FormDataService.post("/user/client-kyc/upload-passport", data);

      return res.data;
    } catch (err) {
      const message = err.response.data.message || err.message;

      dispatch({
        type: CommonActionTypes.REJECTED,
        payload: { message, isError: true },
      });
      return err.response;
    } finally {
      dispatch({ type: CommonActionTypes.RESET, payload: true });
    }
};

export const registerKYCForOldClients = (data, flag = false) => async (dispatch) => {
  dispatch({ type: CommonActionTypes.PENDING, payload: true });

  try {
    const res = flag
      ? await KycUserService.post("/user/register/kyc-web", data)
      : await FormDataService.post("/user/register/kyc-web", data);

    return res.data;
  } catch (err) {
    const message = err.response.data.message || err.message;

    dispatch({
      type: CommonActionTypes.REJECTED,
      payload: { message, isError: true },
    });
    return err.response;
  } finally {
    dispatch({ type: CommonActionTypes.RESET, payload: true });
  }
};


export const CheckClientKYCExpiry = (data) => async (dispatch) => {
  dispatch({ type: CommonActionTypes.PENDING, payload: true });

  try {
    const res = await commonService.post("/user/check-client-kyc-expiry", data);

    return res.data;
  } catch (err) {
    const message = err.response.data.message || err.message;

    dispatch({
      type: CommonActionTypes.REJECTED,
      payload: { message, isError: true },
    });
    return err.response.data;
  } finally {
    dispatch({ type: CommonActionTypes.RESET, payload: true });
  }
};
