import ResponseErrorHandling from "../../../../helper/ResponseErrorHandling";
import commonService from "../../../../services/Common/service";
import { CommonActionTypes } from "../../../action-types";

export const addBeneficiary = (data, isOpenPaydEnabled) => async (dispatch) => {
  dispatch({ type: CommonActionTypes.PENDING, payload: true });

  try {
    // let res;
    // if (isOpenPaydEnabled) {
    //   res = await commonService.post("/client-request/openpayd/add-beneficiary", data);
    // } else {
    //   res = await commonService.post("/client-request/add-beneficiary", data);
    // }
    // const res = await commonService.post("/client-request/add-beneficiary", data);
    const res = await commonService.post("/client-request/openpayd/add-beneficiary", data);
    const beneficiary = res.data;

    return beneficiary;
  } catch (err) {
    const message = err.response.data.message || err.message;

    ResponseErrorHandling(err.response, dispatch);

    dispatch({
      type: CommonActionTypes.REJECTED,
      payload: { message, isError: true },
    });

    return err.response.data;
  } finally {
    dispatch({ type: CommonActionTypes.RESET, payload: true });
  }
};

export const listBeneficiary = () => async (dispatch) => {
  dispatch({ type: CommonActionTypes.PENDING, payload: true });

  try {
    const res = await commonService.post("/client-request/list-beneficiary");
    const { data } = res;

    return data.data;
  } catch (err) {
    const message = err.response.data.message || err.message;

    ResponseErrorHandling(err.response, dispatch);

    dispatch({
      type: CommonActionTypes.REJECTED,
      payload: { message, isError: true },
    });

    return err.response.data;
  } finally {
    dispatch({ type: CommonActionTypes.RESET, payload: true });
  }
};
