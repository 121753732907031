import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCurrencies } from "../../redux/actions/lookups/Currencies";
import { FilterCurrencies } from "../../helper";

const useCurrencies = (filterType = "") => {
  const [allCurrencies, setAllCurrencies] = useState([]);
  const { currencies } = useSelector((state) => state.allCurrencies);
  
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchCurrencies = async () => {
      try {
        const currencies = await dispatch(getCurrencies());
        setAllCurrencies(FilterCurrencies(currencies, filterType));
      } catch (error) {
        console.error("Error fetching currencies:", error);
      }
    };
    if (!currencies) {
      fetchCurrencies();
    } else {
      setAllCurrencies(FilterCurrencies(currencies, filterType));
    }
    // eslint-disable-next-line
  }, []);
  return allCurrencies;
};

export default useCurrencies;
