import React, { useEffect, useState } from "react";
import Label from "../../../../Label";
import { NavigationButton } from "../../../../Buttons";
import CommonButton from "../../../../Buttons/CommonButton";
import TransferGifs from "../partials/TransferGifs";
import FormInputField from "../../../../FormInputField";
import { useDeleteDocument, useForm } from "../../../../../hooks";
import TransactionOTPModal from "../../../../Modals/TransactionsOTPModal";
import {
  exchangeTR,
  updateInternalTR,
} from "../../../../../redux/actions/TRrequest/Exchange";
import { useDispatch } from "react-redux";
import VerifyAccount from "./partials/VerifyAccountNumber";
import {
  FormatBalance,
  GetCurrenySymbol,
  GetValidationErrors,
} from "../../../../../helper";
import Review from "../../../Review";
import TransactionDetail from "../../../TransactionDetail";
import { verifyTROTP } from "../../../../../redux/actions/TRrequest/OTPVerification";
import CustomTransition from "../../../../CustomTransition";
import { setPageTitle } from "../../../../../helper/metaDataHelper";
import { getTransactionDetails } from "../../../../../redux/actions/Transaction/TransactionHistory";
import { setActiveAccount } from "../../../../../redux/actions/home/ActiveAccountAction";
import { useNavigate } from "react-router-dom";
import SelectAccountDropdown from "../../../../Common/SelectAccountDropdown";
import ErrorMessage from "../../../../Common/ErrorMessage";
import PreviousDocs from "../../../../Common/PreviousDocs";
import MultipleFileUpload from "../../../../MultipleFileUpload";

const InternalTransfer = ({
  accounts,
  activeAccount,
  handlePrev,
  handleNext,
  setStep,
  step,
  setIsTransactionConfirmed,
  isTransferConfirmed,
  location,
}) => {
  const [loading, setLoading] = useState(false);
  const [trn_id, setTrnId] = useState("");
  const [isOTPModalOpen, setIsOTPModalOpen] = useState(false);
  const [beneficiaryAccount, setBeneficiaryAccount] = useState("");
  const [supportingDocument, setSupportingDocument] = useState("");
  const { documents, setDocuments, handleDeleteDocument } = useDeleteDocument(
    []
  );

  const [error, setError] = useState([]);

  const fromAccount = useForm(activeAccount);
  const toAccount = useForm("");
  const amount = useForm("", "amount");
  const remarks = useForm("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [files, setFiles] = useState([]);

  useEffect(() => {
    setPageTitle("Internal Transfer");
  }, []);

  useEffect(() => {
    if (location.state) {
      // get transaction details
      const fetchTransactionDetails = async () => {
        setLoading(true);
        const data = {
          trn_id: location.state.transaction.id,
          transaction_nature: "Internal",
        };
        const res = await dispatch(getTransactionDetails(data));

        if (res.success) {
          toAccount.setValue(res.data.receiver_account_number);
          amount.setValue(res.data.amount);
          remarks.setValue(res.data.remarks);
          if (res.data.docs && res.data.docs.length > 0) {
            setDocuments(res.data.docs);
          }
        }
        setLoading(false);
      };
      fetchTransactionDetails();
    }
  }, [location.state, dispatch]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleInternalTransfer = async () => {
    setLoading(true);
    setIsOTPModalOpen(true);
    const today = new Date();
    const formData = new FormData();
    formData.append("account_id", fromAccount.value.id);
    
    if (beneficiaryAccount.account_number) {
      formData.append("account_number", beneficiaryAccount.account_number);
    } else {
      formData.append("account_number", toAccount.value);
    }

    formData.append("amount", amount.value);
    formData.append("remarks", remarks.value);
    formData.append("transaction_date", today.toISOString().split("T")[0]);

    if (supportingDocument) {
      Object.keys(supportingDocument).forEach((key) => {
        supportingDocument[key].forEach((file) => {
          formData.append("supporting_documents[]", file);
        });
      });
    }

    let res;

    try {
      if (location.state) {
        formData.append("trn_id", location.state.transaction.id);
        res = await dispatch(updateInternalTR(formData));
      } else {
        res = await dispatch(exchangeTR(formData));
      }
      if (res.success === true) {
        setTrnId(res.data.trn_id);
        setLoading(false);
        setIsOTPModalOpen(true);
        setIsTransactionConfirmed(true);
        location.state = null;
        return;
      } else if (!res.success) {
        if (res.errors) {
          setError(GetValidationErrors(res.errors));
        } else {
          setError(res.error);
        }

        setStep(2);
        setIsOTPModalOpen(false);
        setTimeout(() => {
          setLoading(false);
        }, 500);
        return;
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const onChangeToAccount = (e) => {
    toAccount.onChange(e);
    if (error) setError("");
    if (beneficiaryAccount) setBeneficiaryAccount("");
  };

  const validateInternal = () => {
    let validate = true;

    if (fromAccount.value.balance <= 0) {
      validate = false;
      fromAccount.setError("Insufficient balance in this account.");
      amount.setError(true);
      return validate;
    }

    if (amount.validate() || amount.value > fromAccount.value.balance) {
      validate = false;
      amount.setError("Please enter a valid amount.");
    }
    if (!remarks.value) {
      validate = false;
      remarks.setError("Please enter remarks.");
    }

    setStep(2);

    return validate;
  };

  const handleFileChange = (name, selectedFiles) => {
    setSupportingDocument((prevFiles) => {
      const updatedFiles = { ...prevFiles, [name]: selectedFiles };
      if (!selectedFiles.length) {
        delete updatedFiles[name];
        return "";
      }
      return updatedFiles;
    });
    if (error) {
      setError([]);
    }
  };

  const handleContinue = () => {
    if (!validateInternal()) return;
    handleNext();
  };

  const handleViewTransaction = async () => {
    setIsOTPModalOpen(false);
    setStep(1);
    navigate("/all-transactions");
    await dispatch(setActiveAccount(fromAccount.value));
  };

  return (
    <div className="min-h-screen">
      {step === 2 && (
        <div className="md:w-[400px] space-y-4">
          {error.length > 0 && (
            <ErrorMessage message={error} onClose={() => setError("")} />
          )}

          <div className="space-y-4">
            <div className="space-x-3">
              <span className="text-paragraph-text text-sm font-normal tracking-tight">
                You’re have
              </span>
              <span
                className={`text-body-text text-sm font-bold tracking-tight
                ${fromAccount.value.balance <= 0 && "text-highlighter-red"}
                `}
              >
                {FormatBalance(fromAccount.value.balance)}{" "}
                {fromAccount.value.currency_code}
              </span>
            </div>

            <div className="space-y-3">
              <Label
                className="text-body-text text-sm font-medium tracking-tight"
                text={"From Account"}
              />
              <SelectAccountDropdown
                title={"From account"}
                accounts={accounts}
                slectedAccount={fromAccount.value}
                setAccount={(account) => {
                  fromAccount.setValue(account);
                  if (fromAccount.error) fromAccount.setError("");
                }}
              />

              {fromAccount.error && (
                <span className="text-highlighter-red text-sm px-2">
                  {fromAccount.error}
                </span>
              )}
            </div>

            <TransferGifs />

            <div className="space-y-3">
              <Label
                className="text-body-text text-sm font-medium tracking-tight"
                text={"To Account"}
              />
              <FormInputField
                label={"To Account"}
                placeholder="Enter beneficiary account number"
                type="text"
                value={toAccount.value}
                onChange={onChangeToAccount}
                error={toAccount.error}
                icon={
                  <VerifyAccount
                    fromAccount={fromAccount.value}
                    toAccountNumber={toAccount}
                    setError={setError}
                    error={error}
                    beneficiaryAccount={beneficiaryAccount}
                    setBeneficiaryAccount={setBeneficiaryAccount}
                    location={location}
                  />
                }
              />
            </div>

            <CustomTransition show={beneficiaryAccount ? true : false}>
              <div className="space-y-3">
                <FormInputField
                  label="Account holder name"
                  placeholder="Beneficiary Name"
                  type="text"
                  value={beneficiaryAccount?.title}
                  readOnly={true}
                />
                <div>
                  <FormInputField
                    label="Amount"
                    placeholder="Enter Amount"
                    type="number"
                    {...amount}
                    currency={GetCurrenySymbol(
                      fromAccount.value.currency_code.split(" ")[0],
                      "13",
                      "13"
                    )}
                  />
                </div>
                <FormInputField
                  label="Remarks"
                  placeholder="Add Note"
                  type="text"
                  {...remarks}
                />

                <MultipleFileUpload
                  name="supporting_documents"
                  id="supporting_documents"
                  title={"Supporting document"}
                  onChange={handleFileChange}
                  isMultiple={true}
                  maxCount={5}
                  mandatory={true}
                  files={files}
                  setFiles={setFiles}
                />

                <PreviousDocs
                  documents={documents}
                  handleDeleteDocument={handleDeleteDocument}
                />
              </div>
            </CustomTransition>
          </div>

          <hr className="border border-divider-border" />

          <div className="flex space-x-4">
            <NavigationButton text={"Back"} onClick={handlePrev} />
            <CommonButton
              type="submit"
              disabled={
                !amount.value ||
                !remarks.value ||
                (!supportingDocument && documents.length === 0) ||
                !beneficiaryAccount.title
              }
              onClick={handleContinue}
            >
              Continue
            </CommonButton>
          </div>
        </div>
      )}

      {step === 3 && (
        <div className="md:w-[568px]">
          <Review
            supportingDocument={supportingDocument}
            handlePrev={handlePrev}
            handleTransaction={handleInternalTransfer}
            amount={`${amount.value} ${fromAccount.value.currency_code}`}
            message=" will be transferred to the below account details."
            isTransactionConfirmed={isTransferConfirmed}
          >
            <TransactionDetail
              label="You’re sending"
              value={`${amount.value} ${fromAccount.value.currency_code} ${fromAccount.value.title}`}
            />
            <TransactionDetail label="Nature" value="Internal Transfer" />
            <hr className="flex w-full border-divider-border" />

            <TransactionDetail
              label="Recipient account name"
              value={beneficiaryAccount.title}
            />
            <TransactionDetail
              label="Recipient account number"
              value={toAccount.value}
            />

            <hr className="flex w-full border-divider-border" />

            <TransactionDetail label="Remarks" value={remarks.value} />
          </Review>
        </div>
      )}

      <TransactionOTPModal
        isLoading={loading}
        setisLoading={setLoading}
        TrnId={trn_id}
        setTrnId={setTrnId}
        isModalOpen={isOTPModalOpen}
        setIsModalOpen={setIsOTPModalOpen}
        handleTransaction={handleInternalTransfer}
        reduxaction={verifyTROTP}
        navigateUrl="/"
        handleViewTransaction={handleViewTransaction}
      />
    </div>
  );
};

export default InternalTransfer;
